import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { FaSquareFacebook } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";

const Home = () => {
  return (
    <section className="coming-soon-section">
      <h1 className="coming-soon-heading">Coming Soon!🏓</h1>
      <div className="social-links">
        <Link
          to={
            "https://www.facebook.com/TableTennis.ge?notif_id=1730744400904959&notif_t=page_user_activity&ref=notif"
          }
          target="_blank"
          className="icon"
        >
          <FaSquareFacebook fill="#7d1423" size={48} />
        </Link>
        <Link
          to={"https://www.tiktok.com/@mechogne.tt.club"}
          target="_blank"
          className="icon"
        >
          <AiFillTikTok fill="#7d1423" size={52} />
        </Link>
      </div>
      <img className="mechogne" src="./favicon.jpg" alt="logo" />
    </section>
  );
};

export default Home;
