import React from "react";
import "./Leaderboard.css";

const Leaderboard = () => {
  return (
    <section className="coming-soon-section">
      <h1 className="coming-soon-heading">Coming Soon!</h1>
    </section>
  );
};

export default Leaderboard;
